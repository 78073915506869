import React, { useEffect, useRef } from "react";
import logo from "./logo.svg";
import "./App.css";

import {
  Box,
  Text,
  Button,
  Card,
  Cell,
  FormField,
  Divider,
  Layout,
  Page,
  SegmentedToggle,
  ThemeProvider,
  WixDesignSystemProvider,
  Loader,
  SkeletonLine,
  SkeletonRectangle,
  SkeletonGroup,
  SkeletonCircle,
  EmptyState,
  Image,
  TextButton,
  Heading,
  Badge,
  CircularProgressBar,
  StatusIndicator,
  LinearProgressBar,
  ThemeProviderProps,
  ThemeInterface
} from "@wix/design-system";
import * as Icons from "@wix/wix-ui-icons-common";

import LinePayIcon from './Icons/linepay-icon.svg'
import AtomeIcon from './Icons/atome-icon.svg'
import JKOPayIcon from './Icons/jkopay-icon.svg'
import PiWalletIcon from './Icons/pi-wallet-icon.svg'
import PlusPayIcon from './Icons/plus-pay-icon.svg'
import EasyWalletIcon from './Icons/easywallet-icon.svg'

import "@wix/design-system/styles.global.css";

// declare global {
//   namespace JSX {
//     interface IntrinsicElements {
//       'apple-pay-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
//     }
//   }
// }

function App() {
  const [transaction, setTransaction] = React.useState<any>(null);
  const [isError, setIsError] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [intentResult, setIntentResult] = React.useState<any>(null);
  const [paymentResult, setPaymentResult] = React.useState<any>(null);

  const [paymentMethod, setPaymentMethod] = React.useState("card");
  const [currentCardMeta, setCurrentCardMeta] = React.useState<any>(null);
  const [canGetPrime, setCanGetPrime] = React.useState(false);
  const [browserSupportPaymentRequest, setBrowserSupportPaymentRequest] =
    React.useState(false);
  const [numberFieldStatus, setNumberFieldStatus] = React.useState(1);
  const [expirationDateFieldStatus, setExpirationDateFieldStatus] =
    React.useState(1);
  const [ccvFieldStatus, setCcvFieldStatus] = React.useState(1);

  // transaction.order = {
  //     "description": {
  //       "billingAddress": {
  //           "firstName": "Wing",
  //           "lastName": "Chan",
  //           "phone": "0912345678",
  //           "email": "wing@certifiedcode.us"
  //       },
  //       "items": [
  //           {
  //               "name": "Test232",
  //               "quantity": 1,
  //               "price": "100",
  //               "_id": "8d9a9c50-1632-4575-83fd-3fad83157442"
  //           }
  //       ],
  //       "totalAmount": "100",
  //       "charges": {},
  //       "buyerInfo": {},
  //       "currency": "USD"
  //   },
  //   "returnUrls": {
  //       "successUrl": "https://cashier-services.wix.com/_api/payment-services-web/redirect/success/13ffbc86-4a19-4c21-b8fe-f0a35c1dcea6",
  //       "errorUrl": "https://cashier-services.wix.com/_api/payment-services-web/redirect/error/13ffbc86-4a19-4c21-b8fe-f0a35c1dcea6?pmName=EmptyPayment&pmUrl=&failureCode=6000",
  //       "cancelUrl": "https://cashier-services.wix.com/_api/payment-services-web/redirect/cancel/13ffbc86-4a19-4c21-b8fe-f0a35c1dcea6",
  //       "pendingUrl": "https://cashier-services.wix.com/_api/payment-services-web/redirect/pending/13ffbc86-4a19-4c21-b8fe-f0a35c1dcea6"
  //   },
  //   "_id": "8d9a9c50-1632-4575-83fd-3fad83157442"
  // }

  var data = {
    supportedNetworks: ["MASTERCARD", "DISCOVER", "VISA"],
    supportedMethods: ["card", "pay_with_google", "apple_pay"],
    displayItems: transaction?.order?.description?.items?.map((item: any) => {
      // {
      //   label: "iPhone8",
      //   amount: {
      //     currency: "TWD",
      //     value: "1.00",
      //   },
      // },
      return {
        label: item.name,
        amount: {
          currency: transaction?.order?.description?.currency,
          value: item.price,
        },
      };
    })
    ,
    total: {
      // label: "付給 TapPay",
      label: "總計",
      amount: {
        currency: transaction?.order?.description?.currency,
        value: transaction?.order?.description?.totalAmount,
      },
    },
    // shippingOptions: [
    //   {
    //     id: "standard",
    //     label: "🚛 Ground Shipping (2 days)",
    //     // apple pay only
    //     detail: "Estimated delivery time: 2 days",
    //     amount: {
    //       currency: "TWD",
    //       value: "5.00",
    //     },
    //   },
    //   {
    //     id: "drone",
    //     label: "🚀 Drone Express (2 hours)",
    //     // apple pay only
    //     detail: "Estimated delivery time: 2 hours",
    //     amount: {
    //       currency: "TWD",
    //       value: "25.00",
    //     },
    //   },
    // ],
    // optional
    options: {
      requestPayerEmail: false,
      requestPayerName: false,
      requestPayerPhone: false,
      requestShipping: false,
      shippingType: "shipping",
    },
  };

  useEffect(() => {
    const transactionId = window.location.pathname.split("/")[1];
    fetch(
      `https://certifiedcode.wixsite.com/payment-provider/_functions/transaction/${transactionId}`
    ).then(async (res) => {
      if (res.status !== 200) {
        setIsError(true);
        return;
      }
      const { data } = await res.json();
      console.log(data);
      setTransaction(data);
      setPaymentMethod(data?.methods[0]);
      if (data.paymentResult) {
        setPaymentResult(data.paymentResult);
      }
    }).catch(() => {
      setIsError(true);
    })
      ;
  }, []);

  useEffect(() => {
    if (!transaction) {
      return;
    }
    window.TPDirect.setupSDK(transaction?.app?.appId, transaction?.app?.appKey, transaction?.app?.env);
    window.TPDirect.card.setup({
      fields: {
        number: {
          element: ".card-number",
          placeholder: "1234 1234 1234 1234",
        },
        expirationDate: {
          element: ".card-expiration-date",
          placeholder: "月份/年份",
        },
        ccv: {
          element: ".card-ccv",
          placeholder: "CVC",
        },
      },
      styles: {
        input: {
          "font-family": "system-ui",
          "font-size": "20px",
          height: "40px",
        },
      },
      // 此設定會顯示卡號輸入正確後，會顯示前六後四碼信用卡卡號
      isMaskCreditCardNumber: false,
      maskCreditCardNumberRange: {
        beginIndex: 6,
        endIndex: 11,
      },
    });
    // listen for TapPay Field
    window.TPDirect.card.onUpdate(function (update: any) {
      console.log(update);
      setCurrentCardMeta(update);
      setCanGetPrime(update.canGetPrime);
      setNumberFieldStatus(update.status.number);
      setExpirationDateFieldStatus(update.status.expiry);
      setCcvFieldStatus(update.status.ccv);

      /* Change card type display when card type change */
      /* ============================================== */

      // cardTypes = ['visa', 'mastercard', ...]
      var newType = update.cardType === "unknown" ? "" : update.cardType;
      // $("#cardtype").text(newType);

      /* Change form-group style when tappay field status change */
      /* ======================================================= */

      // number 欄位是錯誤的
      // if (update.status.number === 2) {
      //   setNumberFormGroupToError(".card-number");
      // } else if (update.status.number === 0) {
      //   setNumberFormGroupToSuccess(".card-number");
      // } else {
      //   setNumberFormGroupToNormal(".card-number");
      // }

      // if (update.status.expiry === 2) {
      //   setNumberFormGroupToError(".card-expiration-date");
      // } else if (update.status.expiry === 0) {
      //   setNumberFormGroupToSuccess(".card-expiration-date");
      // } else {
      //   setNumberFormGroupToNormal(".card-expiration-date");
      // }

      // if (update.status.ccv === 2) {
      //   setNumberFormGroupToError(".card-ccv");
      // } else if (update.status.ccv === 0) {
      //   setNumberFormGroupToSuccess(".card-ccv");
      // } else {
      //   setNumberFormGroupToNormal(".card-ccv");
      // }
    });
    window.TPDirect.paymentRequestApi.setupApplePay({
      // required, your apple merchant id
      merchantIdentifier: transaction?.app?.appleMerchantId,
      // defaults to 'TW'
      countryCode: "TW",
    });
    window.TPDirect.paymentRequestApi.setupPayWithGoogle({
      // defaults to ['CARD', 'TOKENIZED_CARD']
      allowedPaymentMethods: ["CARD", "TOKENIZED_CARD"],

      // Indicates whether or not you allow prepaid debit cards as a form of payment.
      // Set to true to allow prepaid debit cards. Otherwise, set to false.
      // defaults to true
      allowPrepaidCards: true,

      // defaults to false
      billingAddressRequired: false,
      // defaults to  'MIN'
      billingAddressFormat: "MIN", // FULL, MIN

      // Set the ISO 3166-1 alpha-2 formatted country codes of the countries to which shipping is allowed.
      // If not specified, all countries are allowed.
      // defaults to undefined (allow all shipping address)
      // allowedCountryCodes: ['TW']
    });
    window.TPDirect.paymentRequestApi.setupPaymentRequest(
      data,
      function (result: any) {
        console.log(result);
        // result.browserSupportPaymentRequest
        // result.canMakePaymentWithActiveCard
        setBrowserSupportPaymentRequest(
          result.browserSupportPaymentRequest &&
          result.canMakePaymentWithActiveCard &&
          (isIos() ? transaction?.methods?.includes('applepay') : transaction?.methods?.includes('googlepay'))
        )
      }
    );
  }, [transaction]);

  function submitIntent(data: any) {
    fetch(
      `https://certifiedcode.wixsite.com/payment-provider/_functions/intent/${transaction._id}`, {
      body: JSON.stringify(data),
      method: "POST"
    }
    ).then(async (res) => {
      const data = await res.json();
      console.log(data);
      setIntentResult(data.intent);
      setPaymentResult(data.result);
      if (data.intent.payment_url) { window.TPDirect.redirect(data.intent.payment_url) }
      else if (data.result.record_status === 0 || data.result.record_status === 1) {
        setTimeout(() => {
          window.location.href = transaction?.returnUrls?.successUrl
        }, 3000)
      }
    });
  }

  function isIos() {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  }

  function forceBlurIos() {
    if (!isIos()) {
      return;
    }
    var input = document.createElement("input");
    input.setAttribute("type", "text");
    // Insert to active element to ensure scroll lands somewhere relevant
    if (document.activeElement) document.activeElement.prepend(input);
    input.focus();
    if (input.parentNode) input.parentNode.removeChild(input);
  }

  const backButtonUrl = intentResult?.status === 0 ?
    transaction?.returnUrls?.successUrl
    :
    transaction?.returnUrls?.cancelUrl

  const theme: ThemeInterface = {
    color10: "#000000",
    // color20: 'rgba(0, 0, 0, 0.06)',
    textColorPrimary: "#000000",
  }

  if (isError) {
    return <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <ThemeProvider
        theme={theme}
      ></ThemeProvider>
      <Page minWidth={0} sidePadding={20} height={"100%"}>
        <Page.Header
          title={<Layout>
            <Cell>
              <Button
                skin="inverted" size="tiny" prefixIcon={<Icons.ArrowLeftSmall />}
                onClick={() => {
                  window.history.back();
                }}
                disabled={window.history.length === 0}
              >返回</Button>
            </Cell>
          </Layout>}
        ></Page.Header>
        <Page.Content>
          <Layout>
            <Cell span={12}>
              <EmptyState
                theme="page"
                image={
                  <Icons.Block size="100" />
                }
                title="請返回重新選擇支付方式"
                subtitle="我們無法找到此交易，請檢查您的連結是否正確，或聯繫商家。"
              >
                {<TextButton prefixIcon={<Icons.ArrowLeft />}
                  onClick={() => {
                    window.history.back();
                  }}
                  disabled={window.history.length === 0}
                >返回</TextButton>}
              </EmptyState>
            </Cell>
          </Layout>
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  }

  if (!transaction) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <ThemeProvider
          theme={theme}
        >
          <SkeletonGroup skin="light">
            <Page minWidth={0} sidePadding={20} height={"100%"}>
              <Page.Header
                title={<SkeletonRectangle height="24px" width="63px" />}
              ></Page.Header>
              <Page.Content>
                <Layout>
                  <Cell span={12}>
                    <SkeletonRectangle height="36px" width="100%" />
                  </Cell>
                  <Cell span={12}>
                    <SkeletonRectangle height="30px" width="100%" />
                  </Cell>
                  <Cell span={12}>
                    <Card>
                      <Card.Header
                        title={<SkeletonRectangle height="24px" />}
                      />
                      <Card.Divider />
                      <Card.Content>
                        <Layout>
                          <Cell>
                            <SkeletonRectangle
                              height="18px"
                              marginBottom="5px"
                              width="120px"
                            />
                            <SkeletonRectangle height="40px" />
                          </Cell>
                          <Cell>
                            <SkeletonRectangle
                              height="18px"
                              marginBottom="5px"
                              width="120px"
                            />
                            <SkeletonRectangle height="40px" width="200px" />
                          </Cell>
                          <Cell>
                            <SkeletonRectangle
                              height="18px"
                              marginBottom="5px"
                              width="120px"
                            />
                            <SkeletonRectangle height="40px" width="80px" />
                          </Cell>
                        </Layout>
                      </Card.Content>
                    </Card>
                  </Cell>
                  <Cell span={12}>
                    <SkeletonRectangle height="36px" width="100%" />
                  </Cell>
                  <Cell span={12}>
                    <SkeletonLine marginBottom="5px" />
                  </Cell>
                </Layout>
              </Page.Content>
            </Page>
          </SkeletonGroup>
        </ThemeProvider>
      </WixDesignSystemProvider>
    );
  }

  const HeaderContent = () => {
    return <Layout>
      <Cell>
        <Button
          as='a' href={transaction?.returnUrls?.cancelUrl} target='_blank' skin="inverted" size="tiny" prefixIcon={<Icons.ArrowLeftSmall />}>返回</Button>
      </Cell>
      <Cell>
        <Box direction="horizontal" gap="SP1" alignItems="center"><Heading size="tiny">支付 {transaction?.merchant?.name}</Heading>
          {
            transaction?.app?.env === 'sandbox' ? <Badge size="tiny" skin="warning">測試模式</Badge> : <></>
          }
        </Box>
        <Heading>NT${
          transaction?.order?.description?.totalAmount / 100
        }</Heading>
      </Cell>
    </Layout>
  }

  const methods = [{
    key: 'card',
    label: '支付卡',
    icon: <Icons.CreditCard />,
  }, {
    key: 'linepay',
    label: 'LINE PAY',
    icon: <Image src={LinePayIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'linePay',
  }, {
    key: 'paylater',
    label: 'Pay Later',
    icon: <Icons.Mobile />,
    primeKey: 'payLater',
  }, {
    key: 'piwallet',
    label: 'Pi 拍錢包',
    icon: <Image src={PiWalletIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'piWallet',
  }, {
    key: 'pluspay',
    label: '全盈+Pay',
    icon: <Image src={PlusPayIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'plusPay',
  }, {
    key: 'jkopay',
    label: '街口支付',
    icon: <Image src={JKOPayIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'jkoPay',
  },
  {
    key: 'atome',
    label: 'Atome',
    icon: <Image src={AtomeIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'atome',
  },
  {
    key: 'easywallet',
    label: '悠遊付',
    icon: <Image src={EasyWalletIcon} width={18} borderRadius={0} transparent />,
    primeKey: 'easyWallet',
  }
  ]

  const methodsWithRedirect = ["linepay", "jkopay", "paylater", "piwallet", "pluspay", "atome"]

  const cardType = {
    '-1': 'Unknown',
    '1': 'VISA',
    '2': 'MasterCard',
    '3': 'JCB',
    '4': 'Union Pay',
    '5': 'AMEX'
  }

  const fundingType = {
    '-1': 'Unknown',
    '0': '信用卡',
    '1': '簽帳卡',
    '2': '預付卡'
  }

  if (paymentResult?.record_status === 0 || paymentResult?.record_status === 1) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <ThemeProvider
          theme={theme}
        >
          <Page minWidth={0} sidePadding={20}>
            <Page.Header
              title={HeaderContent()}
            ></Page.Header>
            <Page.Content>
              <Layout>
                {methodsWithRedirect.includes(paymentMethod) ? <>
                  <Cell span={12}>
                    <LinearProgressBar value={99} />
                  </Cell>
                </> : <>
                  <Cell span={12}>
                    <Card>
                      <Card.Header
                        title={<Box direction="vertical" gap="SP2"><CircularProgressBar showProgressIndication={false} skin="success" value={100} size="small" /><Text>
                          {paymentResult?.payment_method ===
                            "direct_pay" ? ` •••• ${paymentResult?.card_info?.last_four} 卡` : ``}
                          支付成功</Text></Box>}
                        subtitle="您將返回商家網站以完成訂單"
                      />
                    </Card>
                  </Cell>
                  <Cell span={12}>
                    <Button
                      fullWidth
                      skin="dark"
                      as='a'
                      href={backButtonUrl}
                    >
                      返回
                    </Button>
                  </Cell></>}
              </Layout>
            </Page.Content>
          </Page>
        </ThemeProvider>
      </WixDesignSystemProvider>
    )
  }

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <ThemeProvider
        theme={theme}
      >
        <Page minWidth={0} sidePadding={20} height={"100%"}>
          <Page.Header
            title={HeaderContent()}
          ></Page.Header>
          <Page.Content>
            <Layout>
              <Cell span={12}>
                <div
                  style={{
                    display: browserSupportPaymentRequest ? "block" : "none",
                  }}
                >
                  <Button
                    fullWidth
                    skin="dark"
                    onClick={() => {
                      setIsLoading(true);
                      window.TPDirect.paymentRequestApi.getPrime(function (
                        getPrimeResult: any
                      ) {
                        console.log(getPrimeResult);
                        if (getPrimeResult.status !== 0) {
                          setIsLoading(false);
                          console.log("getPrime failed: " + getPrimeResult.msg);
                          return;
                        }
                        // send prime to server
                        console.log("prime: " + getPrimeResult.prime);
                      });
                    }}
                  >
                    {
                      // check device type to show apple pay or google pay
                      isIos() ? "使用 Apple Pay 支付" : "使用 Google Pay 支付"
                    }
                  </Button>
                </div>
              </Cell>
              {/* <apple-pay-button
                // buttonstyle="black" type="buy" locale="el-GR"
                onClick={() => {
                  window.TPDirect.paymentRequestApi.getPrime(function (getPrimeResult: any) {
                    console.log(getPrimeResult)
                    if (getPrimeResult.status !== 0) {
                      console.log('getPrime failed: ' + getPrimeResult.msg)
                      return
                    }
                    // send prime to server
                    console.log('prime: ' + getPrimeResult.prime)
                  })
                }}></apple-pay-button> */}
              <Cell span={12}>
                <SegmentedToggle
                  defaultSelected="card"
                  size={
                    // (transaction?.methods || []).length > 3 ? "small" : 
                    "small"}
                  onClick={(e: any) => {
                    if (e.target.value)
                      setPaymentMethod(e.target.value);
                  }}
                  selected={paymentMethod}
                >
                  {(transaction?.methods || [])
                    .filter((v: any) => methods.find(m => m.key === v))
                    .map((v: any) => {
                      const method = methods.find(m => m.key === v)
                      if (!method) return <></>
                      return <SegmentedToggle.Button
                        prefixIcon={method?.icon}
                        value={v}
                        selected={paymentMethod === v}
                      >
                        {/* {paymentMethod === v ? method?.label : null} */}
                      </SegmentedToggle.Button>
                    })}
                </SegmentedToggle>
              </Cell>
              {transaction?.methods?.length === 0 ? <>
                <Cell span={12}>
                  <EmptyState
                    theme="page"
                    image={
                      <Icons.Block size="100" />
                    }
                    title="請返回重新選擇支付方式"
                    subtitle="無可用的支付方式"
                  >
                    {<TextButton prefixIcon={<Icons.ArrowLeft />}
                      as='a'
                      href={backButtonUrl}
                    >返回</TextButton>}
                  </EmptyState>
                </Cell>
              </> : <></>}
              <div
                style={{
                  display: paymentMethod === "card" ? 'grid' : 'none',
                  gridArea: 'span 1 / span 12',
                  gap: '24px',
                  gridAutoRows: 'auto'
                }}
              >
                <Cell span={12}>
                  <Card>
                    <Card.Header title="支付卡資訊" />
                    <Card.Divider />
                    <Card.Content>
                      <Layout>
                        <Cell>
                          <FormField
                            label="卡號碼"
                            infoContent="為卡片正面的 15/16 碼數字"
                            status={
                              numberFieldStatus === 2 ? "error" : undefined
                            }
                            statusMessage={
                              numberFieldStatus === 2 ? "您的卡號無效。" : undefined
                            }
                          >
                            <div className="input card-number" />
                          </FormField>
                        </Cell>
                        <Cell>
                          <FormField
                            label="有效期限"
                            infoContent="為卡片正面的 4 碼數字"
                            status={
                              expirationDateFieldStatus === 2
                                ? "error"
                                : undefined
                            }
                            statusMessage={
                              expirationDateFieldStatus === 2
                                ? "您的支付卡的有效期無效。"
                                : undefined
                            }
                          >
                            <div className="input card-expiration-date" />
                          </FormField>
                        </Cell>
                        <Cell>
                          <FormField
                            label="安全碼"
                            infoContent="為卡片背面的 3/4 碼數字"
                            status={
                              ccvFieldStatus === 2 ? "error" : undefined
                            }
                            statusMessage={
                              ccvFieldStatus === 2 ? "您的支付卡的安全碼不完整。" : undefined
                            }
                          >
                            <div className="input card-ccv" />
                          </FormField>
                        </Cell>
                        {(intentResult?.status !== 0 && intentResult?.status) ? <Cell>
                          <Box gap="SP1"><StatusIndicator
                            status="error"
                          /><Text size="small" skin="error">您的支付卡被拒絕了{intentResult?.msg ? `，${intentResult?.msg}` : ''}。{(transaction.app.env === 'sandbox' ? <>您的請求處於測試模式，但使用的是非測試卡。如需查看有效測試卡的清單，<a href='https://docs.tappaysdk.com/tutorial/zh/reference.html#test-card' target='_blank'>請查看我們的文檔</a>。</> : '')}</Text></Box>
                        </Cell> : <></>}
                      </Layout>
                    </Card.Content>
                  </Card>
                </Cell>
                <Cell span={12}>
                  <Button
                    fullWidth
                    skin="dark"
                    disabled={!canGetPrime}
                    onClick={() => {
                      // fix keyboard issue in iOS device
                      forceBlurIos();

                      const tappayStatus =
                        window.TPDirect.card.getTappayFieldsStatus();
                      console.log(tappayStatus);

                      // Check TPDirect.card.getTappayFieldsStatus().canGetPrime before TPDirect.card.getPrime
                      if (tappayStatus.canGetPrime === false) {
                        alert("can not get prime");
                        return;
                      }

                      // Get prime

                      setIsLoading(true);
                      window.TPDirect.card.getPrime(function (result: any) {
                        console.log(result)
                        if (result.status !== 0) {
                          setIsLoading(false);
                          alert("get prime error " + result.msg);
                          return;
                        } else {
                          submitIntent({
                            prime: result.card.prime,
                            metadata: currentCardMeta
                          })
                        }
                      });
                    }}
                  >
                    {isLoading ? <Loader size="tiny" /> : "支付"}
                  </Button>
                </Cell>
              </div>
              {methodsWithRedirect.includes(paymentMethod) ? (
                <>
                  <Cell span={12}>
                    <Card>
                      <Card.Header
                        title={methods.find(m => m.key === paymentMethod)?.label}
                        subtitle={`你將被重新導向至 ${methods.find(m => m.key === paymentMethod)?.label
                          } 進行支付`}
                      />
                    </Card>
                  </Cell>
                  <Cell span={12}>
                    <Button
                      fullWidth
                      skin="dark"
                      onClick={() => {
                        console.log(methods.find(m => m.key === paymentMethod)?.primeKey || "")
                        setIsLoading(true);
                        window.TPDirect[methods.find(m => m.key === paymentMethod)?.primeKey || ""]
                          .getPrime(function (result: any) {
                            console.log(result)
                            if (result.status !== 0) {
                              setIsLoading(false);
                              alert("get prime error " + result.msg);
                              return;
                            } else {
                              submitIntent({
                                prime: result.prime
                              })
                            }
                          });
                      }}
                    >
                      {isLoading ? <Loader size="tiny" /> : "繼續"}
                    </Button>
                  </Cell>
                </>
              ) : (
                <></>
              )}
              <Cell span={12}>
                <Box gap="SP1">
                  <Text skin="disabled" size="tiny">
                    TapPay 處理此支付
                  </Text>
                  <Divider direction="vertical" />
                  <Text skin="standard" size="tiny">
                    條款
                  </Text>
                  <Divider direction="vertical" />
                  <Text skin="standard" size="tiny">
                    隱私
                  </Text>
                </Box>
              </Cell>
            </Layout>
          </Page.Content>
        </Page>
      </ThemeProvider>
    </WixDesignSystemProvider>
  );
}

export default App;
